<template lang="pug">
.add-post
	.save-post
		button(v-if="postVacancies && postVacancies.length" @click="openModal('PublishPost')") გამოქვეყნება
		button(@click="savePost" :class="[(postVacancies && postVacancies.length) && 'sent']") შენახვა
	.add-post-main
		PostFilters(
			@set-vacancy-data="setVacancyData"
			@add-vacancy="addVacancy"
			@delete-vacancy="val => this.openModal('DeleteFilter', val)"
			@change-is-vacancy="isVacancy=false"
			v-model:numberOfModel="vacancyData.number_of_model_needed"
			:isPublished="isPublished"
			:isVacancy="isVacancy"
			:class="[!postId && ' closed']"
		)
		PostDescription(@setNewPostData="setPostDescription")
		PostCategories(@setNewPostData="setPostCategories")
</template>

<script>
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";
import {ajax, apiUrls} from "../api/urls";

import PostFilters from "../components/add-post/PostFilters.vue";
import PostDescription from "../components/add-post/PostDescription.vue";
import PostCategories from "../components/add-post/PostCategories.vue";

export default {
	name: "AddPost",
	components: {
		PostCategories,
		PostDescription,
		PostFilters,
	},
	setup() {
		const toast = useToast()
		return {toast}
	},
	data() {
		return {
			postId: null,
			postData: {},
			vacancyData: {
				number_of_model_needed: 1,
				gender: 0,
				min_age: 0,
				max_age: 100,
				min_height: 0,
				max_height: 200,
				min_weight: 0,
				max_weight: 200,
				post: null,
				hair_colors: [],
				eye_colors: [],
				skin_colors: [],
				other_characteristics: []
			},
			isPublished: false,
			isVacancy: true,
			vacancyIdTemp: null
		}
	},
	computed: {
		...mapGetters({
			postVacancies: 'posts/postVacancies',
			modalValue: 'modal/modalValue'
		})
	},
	mounted() {
		this.$store.dispatch("user/userData")
		this.$store.dispatch("posts/fetchFilters")
	},
	unmounted() {
		this.$store.commit("posts/setSinglePost", {})
	},
	watch: {
		modalValue: {
			handler(newVal) {
				if (newVal?.success && newVal?.name === 'DeleteFilter') {
					this.deleteVacancy()
				}
				if (newVal?.success && newVal?.name === 'PublishPost') {
					this.publishPost()
				}
			},
			deep: true
		}
	},
	methods: {
		// vacancy
		setVacancyData(val) {
			if (val.filterId === "characteristics") {
				let element = this.vacancyData.other_characteristics?.findIndex(el => el === val.selectedId)
				if (element === -1) {
					this.vacancyData.other_characteristics.push(val.selectedId)
				} else {
					this.vacancyData.other_characteristics?.splice(element, 1)
				}
			}

			if (val.filterId === "hair_color") {
				let element = this.vacancyData.hair_colors?.findIndex(el => el === val.selectedId)
				if (element === -1) {
					this.vacancyData.hair_colors.push(val.selectedId)
				} else {
					this.vacancyData.hair_colors?.splice(element, 1)
				}
			}

			if (val.filterId === "skin_color") {
				let element = this.vacancyData.skin_colors?.findIndex(el => el === val.selectedId)
				if (element === -1) {
					this.vacancyData.skin_colors.push(val.selectedId)
				} else {
					this.vacancyData.skin_colors?.splice(element, 1)
				}
			}

			if (val.filterId === "eye_color") {
				let element = this.vacancyData.eye_colors?.findIndex(el => el === val.selectedId)
				if (element === -1) {
					this.vacancyData.eye_colors.push(val.selectedId)
				} else {
					this.vacancyData.eye_colors?.splice(element, 1)
				}
			}

			if (val.filterId === "gender") {
				this.vacancyData.gender = val.selectedId
			}

			if (val.filterId === "age") {
				this.vacancyData.min_age = val.value[0]
				this.vacancyData.max_age = val.value[1]
			}

			if (val.filterId === "height") {
				this.vacancyData.min_height = val.value[0]
				this.vacancyData.max_height = val.value[1]
			}

			if (val.filterId === "weight") {
				this.vacancyData.min_weight = val.value[0]
				this.vacancyData.max_weight = val.value[1]
			}
		},
		addVacancy() {
			ajax.post(apiUrls.addVacancy, this.vacancyData).then(() => {
				this.$store.dispatch('posts/fetchSinglePost', this.postId)
				this.isVacancy = true
				this.vacancyData = {
					number_of_model_needed: 1,
					gender: 0,
					min_age: 0,
					max_age: 100,
					min_height: 0,
					max_height: 200,
					min_weight: 0,
					max_weight: 200,
					post: null,
					hair_colors: [],
					eye_colors: [],
					skin_colors: [],
					other_characteristics: []
				}
				this.vacancyData.post = this.postId
			}).catch(() => {
			})
		},
		deleteVacancy() {
			ajax.delete(apiUrls.deleteVacancy(this.vacancyIdTemp)).then(() => {
				this.$store.dispatch('posts/fetchSinglePost', this.postId)
			}).catch(() => {
			})
		},

		// modal
		openModal(component, val) {
			this.vacancyIdTemp = val
			this.$store.commit("modal/setModalComponent", {
				type: component,
				withContainer: false,
				fullscreen: true,
				componentData: {
					data: [], // from api
					modalContent: true
				}
			})
		},

		// post
		setPostDescription(val) {
			this.postData = {...this.postData, description: val.description, is_salary: val.is_salary}
		},
		setPostCategories(val) {
			this.postData = {...this.postData, tags: val.tags, start_date: val.start_date, end_date: val.end_date}
		},
		savePost() {
			if (!this.postId) {
				// add post
				this.$store.dispatch('posts/addPost', this.postData).then(res => {
					this.vacancyData.post = res.data.id
					this.postId = res.data.id
					this.postData = {}
					this.$store.dispatch('posts/fetchSinglePost', res.data.id)
					this.toast.success("პოსტი წარმატებით შეინახა")
					this.$router.push({name: 'edit-post', params: {postId: res.data.id}})
				})
			} else {
				// update posts
				this.$store.dispatch('posts/updatePost', {postId: this.postId, postData: this.postData}).then(() => {
				})
			}
		},
		publishPost() {
			// publish post
			this.$store.dispatch('posts/publishPost', this.postId).then(() => {
				this.$store.commit('posts/setSinglePost', {})
				this.$store.commit("modal/setModalComponent", {modalValue: {}})
				this.postId = null
				this.isPublished = true
				this.vacancyData = {
					number_of_model_needed: null,
					gender: 0,
					min_age: 0,
					max_age: 100,
					min_height: 0,
					max_height: 200,
					min_weight: 0,
					max_weight: 200,
					post: null,
					hair_colors: [],
					eye_colors: [],
					skin_colors: [],
					other_characteristics: []
				}
				this.$router.push('/')
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.add-post {
	min-height: calc(100vh - 108px);
	position: relative;
	display: flex;
	flex-direction: column;

	.save-post {
		width: 100%;
		flex-grow: 0;
		display: flex;
		align-items: center;
		justify-content: right;
		gap: 20px;
		margin-bottom: 25px;

		> button {
			border: none;
			background: #92EB8E 0 0 no-repeat padding-box;
			border-radius: 10px;
			padding: 7px 20px;
			font: normal normal bold 14px/16px 'Helvetica-Bold';
			letter-spacing: 0;
			color: #FFFFFF;
		}

		> button.sent {
			pointer-events: none;
			background: #F5D98D 0 0 no-repeat padding-box;
		}
	}

	.add-post-main {
		display: grid;
		grid-template-columns: 1fr 1fr 300px;
		grid-column-gap: 20px;
		flex-grow: 1;

		:deep(.closed) {
			> .post-filters-main-content {
				opacity: .6;
				pointer-events: none;
			}
		}
	}
}
</style>
